import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { useEffect, useState } from 'react';

import { BooleanCheckBoxInputField } from '../../../models/Steps/Fields';
import { useStore } from '../../../hooks/useStore';
import { replacePlaceholders } from '../utils/replacePlaceholders';
interface BooleanCheckBoxInputProps {
    field: BooleanCheckBoxInputField;
}

export const BooleanCheckBoxInput = ({ field }: BooleanCheckBoxInputProps) => {
    const {
        createWorkflowRunStore: { setWorkflowRunDataField, workflowRunData, setWorkflowRunData },
        workFlowStore: { selectedWorkflow, setSelectedWorkflow },
    } = useStore();

    const [isChecked, setIsChecked] = useState(false);


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked;
        setIsChecked(newValue);
        const res = setWorkflowRunDataField(field.name, newValue);

        setWorkflowRunData(res);
    };

    return (
        <Box sx={{ marginY: 1 }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isChecked}
                        onChange={handleChange}
                    />
                }
                label={field.label}
            />
        </Box>
    );
}; 