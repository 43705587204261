import { useEffect, useState } from "react";
import { useStore } from "../../hooks/useStore";
import { Box, Paper } from "@mui/material";

export interface EmployeeHandbookResultInterface {
  results_file_key: string;
  summary: string;
}

const EmployeeHandbookResultsPage = () => {
  const [workflowResult, setWorkflowResult] = useState<EmployeeHandbookResultInterface>();

  const {
    workFlowStore: { selectedWorkflowRun },
  } = useStore();

  useEffect(() => {
    setWorkflowResult(selectedWorkflowRun.result);
  }, [selectedWorkflowRun.id]);

  return (
    <Box sx={{ 
      padding: 2,
      maxHeight: '80vh',
      overflowY: 'auto'
    }}>
      {workflowResult && (
        <Paper 
          elevation={0} 
          sx={{ 
            mb: 3,
            '& pre': {
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-all',
              overflow: 'hidden',
              marginBottom: 2
            }
          }}
        >
          <pre>{workflowResult.summary}</pre>
        </Paper>
      )}
    </Box>
  );
};

export default EmployeeHandbookResultsPage;
