import { set } from 'lodash';
import { GenericStep } from '../../../models/Steps/StepInterfaces';
import { Workflow } from '../../../models/Workflows';

export const replacePlaceholders = (workflow: Workflow, workflowRunData: any, setWorkflowRunDataField: any) => {
  const newConfig = JSON.parse(JSON.stringify(workflow));

  const replacePlaceholder = (value: any) => {
    if (typeof value === 'string') {
      // Handle configuration placeholders
      if (value.startsWith('configuration[') && value.endsWith(']')) {
        const configKey = value.slice(14, -1); // Remove 'configuration[' and ']'
        return workflow.configuration[configKey] || value;
      }
      // Handle existing placeholder logic
      if (value.startsWith('[') && value.endsWith(']')) {
        const placeholder = value.slice(1, -1);
        return workflowRunData[placeholder] || value;
      }
    }
    return value;
  };

  newConfig.steps.forEach((step: GenericStep) => {
    step.stepConfiguration?.fields?.forEach((field: any) => {
      Object.keys(field).forEach(key => {
        if (typeof field[key] === 'string') {
          const value = replacePlaceholder(field[key]);
          field[key] = value;
          setWorkflowRunDataField(key, value);
        }
      });
    });
  });

  return newConfig;
};
