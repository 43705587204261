import { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import { LightbulbOutlined } from '@mui/icons-material';

import { PromptInputField } from '../../../models/Steps/Fields';
import { useStore } from '../../../hooks/useStore';
import ChooseFromPromptsGalleryModal from '../../../pages/PromptsGalleryPage/ChooseFromPromptsGalleryModal';
import { INITIAL_PROMPT } from '../../../models/Prompt';

type PromptInputProps = {
    field: PromptInputField;
};

const PromptInput = ({
    field: { name: fieldName, label, defaultValue },
}: PromptInputProps) => {
    const {
        createWorkflowRunStore: { setWorkflowRunDataField },
        promptsStore: { getPromptsByUserTeams, getPromptsByUser, setCurrentPrompt },
    } = useStore();

    const [isPromptGalleryOpen, setIsPromptGalleryOpen] = useState(false);
    const [promptContent, setPromptContent] = useState(defaultValue || '');

    useEffect(() => {
        const fetchPrompts = async () => {
            await getPromptsByUserTeams();
            await getPromptsByUser();
        };

        fetchPrompts();
    }, []);

    useEffect(() => {
        setWorkflowRunDataField(fieldName, promptContent || '');
    }, [promptContent]);

    return (
        <>
            <TextField
                label={label}
                multiline
                maxRows={20}
                value={promptContent}
                onChange={(e) => setPromptContent(e.target.value)}
                style={{ marginTop: '24px', resize: 'vertical' }}
                fullWidth
            />

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    cursor: 'pointer',
                    marginBottom: 1,
                    marginTop: '8px'
                }}
                onClick={() => setIsPromptGalleryOpen(true)}
            >
                <span>Choose from Prompts Gallery</span>
                <LightbulbOutlined />
            </Box>

            <ChooseFromPromptsGalleryModal
                isOpen={isPromptGalleryOpen}
                onClose={() => setIsPromptGalleryOpen(false)}
                onPromptSelect={(content) => {
                    setIsPromptGalleryOpen(false);
                    setCurrentPrompt(INITIAL_PROMPT);
                    setPromptContent(content)
                }}
            />
        </>
    );
};

export default observer(PromptInput);
