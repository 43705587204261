import React, { useState } from 'react';
import { observer } from 'mobx-react';
import TableRow from '@mui/material/TableRow';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import Typography from '@mui/material/Typography';
import Box from '@material-ui/core/Box/Box';
import { MoonLoader } from 'react-spinners';
import { CheckCircleOutline } from '@mui/icons-material';
import { styled, TableCell } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import { S3Folders } from '../../../constants/S3Folders';
import { useStore } from '../../../hooks/useStore';
import { downloadDocumentSecurely, getDocumentsKey, uploadDocumentSecurely } from '../../../utils/documentsS3';
import Flex from '../../utils/flex/Flex';
import { GRAY_COLORS } from '../../../constants/colors';
import { getIconByName, TypeIcon } from '../../knowledgeBase/utils';
import { convertBytesToUnit } from '../../../utils/convertBytesToUnits';
import { FileSources } from '../../../constants/FileSources';
import { fileStatus } from '../../../constants/fileStatus';

const S3FileTableRow = ({ item, isFolder, selectionMode = false }: any) => {
  const {
    s3FileSystemStore: {
      setPrefix,
      overrideObjectInS3Data,
      bucketName,
      isUploadInProgress,
      setIsUploadInProgress,
      s3FilesToAddToKnowledgeBase,
      setS3FilesToAddToKnowledgeBase,
    },
    knowledgeBaseStore: { currentKnowledgeBase },
    fileStore: { createFile },
    appState: { s3DocumentsApi, fileApi },
  } = useStore();

  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleUploadFile = async () => {
    setIsUploadInProgress(true);
    setLoading(true);

    const preSignedReadUrl = await s3DocumentsApi.generateExternalReadUrl(item.key, bucketName);

    const blob = await downloadDocumentSecurely(preSignedReadUrl.signedUrl);
    const file = new File([blob], item.name, { type: blob.type });
    const newFileDocumentsKey = getDocumentsKey(`${S3Folders.knowledgeBases}/${currentKnowledgeBase.id}`, file.name);

    const preSignedPutUrl = await s3DocumentsApi.generateDocumentsWriteUrl(newFileDocumentsKey, 'documents');

    const createdFileEntry = await createFile({
      key: newFileDocumentsKey,
      name: file.name,
      source: FileSources.S3,
      status: fileStatus.UPLOADED,
    });

    setS3FilesToAddToKnowledgeBase([...s3FilesToAddToKnowledgeBase, createdFileEntry]);

    const status = await uploadDocumentSecurely(preSignedPutUrl, file, {
      setProgress: (progress: number) => setUploadProgress(progress),
    });

    if (status === 200) {
      setLoading(false);
      setIsUploadInProgress(false);
    }
  };

  const removeFileByKey = async () => {
    setLoading(true);
    let documentKeyOfItemToBeDeleted: string = '';

    setS3FilesToAddToKnowledgeBase(
      s3FilesToAddToKnowledgeBase.filter(file => {
        const isEqual = file.name === item.name;

        if (isEqual) {
          documentKeyOfItemToBeDeleted = file.key;
        }

        return !isEqual;
      })
    );

    await s3DocumentsApi.deleteFile(documentKeyOfItemToBeDeleted);
    setLoading(false);
  };

  const handleSelection = () => {
    if (isFolder) return;

    const isSelected = s3FilesToAddToKnowledgeBase.some(
      (file) => file.key === item.key
    );

    if (isSelected) {
      setS3FilesToAddToKnowledgeBase(
        s3FilesToAddToKnowledgeBase.filter((file) => file.key !== item.key)
      );
    } else {
      setS3FilesToAddToKnowledgeBase([...s3FilesToAddToKnowledgeBase, item]);
    }

    overrideObjectInS3Data({
      ...item,
      isSelected: !item.isSelected,
    });
  };

  const isSelected = s3FilesToAddToKnowledgeBase.some(
    (file) => file.key === item.key
  );

  return (
    <TableRow
      sx={isUploadInProgress ? { cursor: 'pointer', pointerEvents: 'none', opacity: 0.7 } : { cursor: 'pointer' }}
      onClick={async () => {
        if (isFolder) {
          setPrefix(item.path);
          return;
        }

        if (selectionMode) {
          handleSelection();
          return;
        }

        if (!item.isSelected) {
          await handleUploadFile();
        } else {
          removeFileByKey();
        }

        overrideObjectInS3Data({
          ...item,
          isSelected: !item.isSelected,
        });
      }}
    >
      <StyledTableCell>
        <Flex sx={{ alignItems: 'center' }}>
          {selectionMode && !isFolder && (
            <Checkbox
              checked={isSelected}
              onChange={(e) => {
                e.stopPropagation();
                handleSelection();
              }}
              onClick={(e) => e.stopPropagation()}
            />
          )}
          {isFolder ? (
            <FolderOpenIcon sx={{ marginRight: '6px', color: GRAY_COLORS.GRAY_5 }} />
          ) : (
            <TypeIcon src={getIconByName(item.name)} alt={item.name} />
          )}
          <Typography variant={'body2'}>{item.name}</Typography>
          {loading && (
            <Box sx={{ marginLeft: '6px' }}>
              <MoonLoader color={GRAY_COLORS.GRAY_5} size={16} />
            </Box>
          )}
          {item.isSelected && <CheckCircleOutline color={'success'} sx={{ marginLeft: '6px', fontSize: '20px' }} />}
        </Flex>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant={'body2'}>
          {(item.lastModified && new Date(item.lastModified).toLocaleDateString()) || '-'}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant={'body2'}>{(item.size && convertBytesToUnit(item.size)) || '-'}</Typography>
      </StyledTableCell>
    </TableRow>
  );
};

const StyledTableCell = styled(TableCell)`
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  background: inherit !important;
  padding-top: 14px;
  padding-bottom: 12px;
`;

export default observer(S3FileTableRow);