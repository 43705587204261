import { observer } from 'mobx-react';
import FullHeightScreenWrapper from '../../components/FullHeightComponentWrapper/FullHeightScreenWrapper';
import CKEditor from './CKEditor';
import styled from 'styled-components';

const TextEditorDemoPage = () => {
  return (
    <StyledFullHeightScreenWrapper>
      <CKEditor />
    </StyledFullHeightScreenWrapper>
  );
};

const StyledFullHeightScreenWrapper = styled(FullHeightScreenWrapper)`
  height: calc(100vh - 56px) !important;
`;

export default observer(TextEditorDemoPage);

// const TinyMCEditor = () => {
//   const {
//     conversationStore: { createCompletion, conversationSettingsState },
//     modelStore: { defaultOrganizationModel },
//   } = useStore();
//
//   const parseTinyAiRequestObject = (obj: any) => {
//     // 1. Join system instructions (if any) into one string
//     const systemInstructions = (obj.system || []).join('\n');
//     // 2. Extract context
//     const context = obj.context || '';
//     // 3. Extract user’s question
//     const question = obj.query || '';
//     // 4. Extract the thread array
//     const thread = obj.thread || [];
//
//     // 5. Check if we have a "last response" from the thread
//     let lastThreadResponse = '';
//     if (thread.length > 0) {
//       const lastEvent = thread[thread.length - 1];
//       // Attempt to read `lastEvent.response.data` if it exists
//       if (lastEvent.response && typeof lastEvent.response.data === 'string') {
//         lastThreadResponse = lastEvent.response.data;
//       }
//     }
//
//     console.log('question ------------------->> ', question);
//
//     let prompt = '';
//
//     // CASE 1: No thread or thread is empty
//     prompt = `${systemInstructions}
//
// Context:\"\"\"${context}\"\"\"
//
// Question:\"\"\"${question}\"\"\"
//
// Answer:`;
//
//     return prompt;
//   };
//
//   return (
//     <Editor
//       apiKey="dnllb9mk22p7l49li1m6cjvijpqapq9r6myrbl255e0qbumy"
//       init={{
//         plugins: [
//           'importword',
//           'exportword',
//           'exportpdf',
//           'ai',
//           'preview',
//           'powerpaste',
//           'casechange',
//           'importcss',
//           'tinydrive',
//           'searchreplace',
//           'autolink',
//           'autosave',
//           'save',
//           'directionality',
//           'advcode',
//           'visualblocks',
//           'visualchars',
//           'fullscreen',
//           'image',
//           'link',
//           'math',
//           'media',
//           'mediaembed',
//           'codesample',
//           'table',
//           'charmap',
//           'pagebreak',
//           'nonbreaking',
//           'anchor',
//           'tableofcontents',
//           'insertdatetime',
//           'advlist',
//           'lists',
//           'checklist',
//           'wordcount',
//           'tinymcespellchecker',
//           'a11ychecker',
//           'editimage',
//           'help',
//           'formatpainter',
//           'permanentpen',
//           'pageembed',
//           'charmap',
//           'tinycomments',
//           'mentions',
//           'quickbars',
//           'linkchecker',
//           'emoticons',
//           'advtable',
//           'footnotes',
//           'mergetags',
//           'autocorrect',
//           'typography',
//           'advtemplate',
//           'markdown',
//           // 'revisionhistory',
//         ],
//         toolbar:
//           'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
//         tinycomments_mode: 'embedded',
//         tinycomments_author: 'Author name',
//         mergetags_list: [
//           { value: 'First.Name', title: 'First Name' },
//           { value: 'Email', title: 'Email' },
//         ],
//         ai_request: async (request: any, respondWith: any) => {
//           console.log('request 2222 ------------------->> ', request);
//
//           console.log('parseTinyAiRequestObject(request) ------------------->> ', parseTinyAiRequestObject(request));
//
//           respondWith.string(async () => {
//             const createCompletionDto: CreateCompletionRequestDto = {
//               message: parseTinyAiRequestObject(request),
//               settings: {
//                 providerName: conversationSettingsState.providerName,
//                 modelVersion: conversationSettingsState.model,
//                 temperature: 0.7,
//                 maxTokens: 4096,
//               },
//             };
//             const response = await createCompletion(createCompletionDto);
//
//             const message = response[response.length - 1].message;
//
//             console.log('message ------------------->> ', message);
//
//             return message;
//           });
//         },
//       }}
//       initialValue="Welcome to TinyMCE!"
//     />
//   );
// };
