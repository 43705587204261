import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';

import S3FileExplorer from '../../../S3FileSystemNavigator/S3FileExplorer';
import { useStore } from '../../../../hooks/useStore';
import { toJS } from 'mobx';

interface S3FileSelectorProps {
    field: {
        name: string;
        s3BucketName: string;
        initialPath: string;
    };
}

const S3FileSelector = ({ field }: S3FileSelectorProps) => {
    const {
        s3FileSystemStore: { setBucketName, getS3Data, setS3Data, setPrefix, prefix },
        createWorkflowRunStore: { setWorkflowRunDataField, workflowRunData },
    } = useStore();

    useEffect(() => {
        setPrefix(field.initialPath || '');
        setS3Data(undefined);

        return () => {
            setS3Data(undefined);
            setPrefix('');
        };
    }, []);

    useEffect(() => {
        (async () => {
            setBucketName(field.s3BucketName || 'dev-avm-documents-addvaluemachine');

            if (prefix) {
                console.log('field.s3BucketName', field.s3BucketName, prefix);
                setTimeout(async () => {
                    await getS3Data(field.s3BucketName || 'dev-avm-documents-addvaluemachine', prefix);
                }, 200);
            }
        })();
    }, [field.s3BucketName, prefix]);

    const onFilesSelected = (selectedFiles: any[]) => {
        // Update the workflow run data directly with the selected S3 files
        setWorkflowRunDataField(
            field.name,
            [...(workflowRunData[field.name] || []), ...selectedFiles]
        );
    };

    const handleRemoveFile = (fileToRemove: any) => {
        const currentFiles = workflowRunData[field.name] || [];
        const updatedFiles = currentFiles.filter((file: any) => file.key !== fileToRemove.key);
        setWorkflowRunDataField(field.name, updatedFiles);
    };

    return (
        <Box>
            <S3FileExplorer
                selectionMode={true}
                onSelectionConfirm={(selectedFiles: any) => {
                    onFilesSelected(selectedFiles);
                }}
            />

            {/* Display selected files */}
            {workflowRunData[field.name]?.length > 0 && (
                <Box sx={{ mt: 2 }}>
                    <Box sx={{ fontWeight: 'bold', mb: 1 }}>Selected Files:</Box>
                    {workflowRunData[field.name].map((file: any) => (
                        <Box
                            key={file.key}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                p: 1,
                                mb: 0.5,
                                border: '1px solid #e0e0e0',
                                borderRadius: 1,
                            }}
                        >
                            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {file.key}
                            </Box>
                            <Box
                                component="button"
                                onClick={() => handleRemoveFile(file)}
                                sx={{
                                    border: 'none',
                                    background: 'none',
                                    color: 'error.main',
                                    cursor: 'pointer',
                                    '&:hover': { opacity: 0.8 },
                                }}
                            >
                                Remove
                            </Box>
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default observer(S3FileSelector);