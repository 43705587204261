import { ChangeEvent, useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { observer } from 'mobx-react';

import { TextInputField } from '../../../models/Steps/Fields';
import { useStore } from '../../../hooks/useStore';
import { COLOR_WHITE } from '../../../constants/colors';

type TextInputFieldProps = {
    field: TextInputField;
};

const PromptNameInput = ({
    field: { name: fieldName, label, defaultValue = '' },
}: TextInputFieldProps) => {
    const {
        createWorkflowRunStore: { setWorkflowRunDataField },
        promptsStore: { currentPrompt },
    } = useStore();

    useEffect(() => {
        if (currentPrompt.title) {
            setFieldValue(currentPrompt.title);
        }
    }, [currentPrompt]);

    useEffect(() => {
        setWorkflowRunDataField(fieldName, defaultValue);
    }, []);

    const [fieldValue, setFieldValue] = useState(defaultValue);

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        setFieldValue(newValue);
        setWorkflowRunDataField(fieldName, newValue);
    };

    return (
        <Box sx={{ background: COLOR_WHITE }}>
            <TextField
                id={fieldName}
                label={label}
                value={fieldValue}
                onChange={handleChange}
                placeholder={`Enter ${label}...`}
                variant="outlined"
                fullWidth
                margin="normal"
            />
        </Box>
    );
};

export default observer(PromptNameInput);
