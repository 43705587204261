import WorkflowStepFileInput from '../Inputs/WorkflowStepFileInput';
import DynamicTextInputList from '../Inputs/DynamicTextInputList';
import WorkflowStepTextInputField from '../Inputs/WorkflowStepTextInput';
import WorkflowStepSelectInput from '../Inputs/WorkflowStepSelectInput';
import WorkflowStepNumberInput from '../Inputs/WorkflowStepNumberInput';
import DynamicFormInputsList from '../Inputs/DynamicFormInputsList';
import S3FileSelector from '../Inputs/S3FileSelectionInput/S3FileSelectionInput';
import PromptInput from '../Inputs/PromptInput';
import PromptNameInput from '../Inputs/PromptNameInput';
import { BooleanCheckBoxInput } from '../Inputs/BooleanCheckBoxInput';

export const renderField = (field: any) => {
  // console.log('field', field.name, field.displayInput);
  if (field.displayInput !== undefined && field.displayInput !== true) {
    return null;
  }

  switch (field.type) {
    case 'promptInput':
      return <PromptInput key={field.name} field={field} />;
    case 'promptNameInput':
      return <PromptNameInput key={field.name} field={field} />;
    case 'booleanCheckBoxInput':
      return <BooleanCheckBoxInput key={field.name} field={field} />;
    case 'fileInput':
      return <WorkflowStepFileInput key={field.name} field={field} />;
    case 'dynamicTextInputList':
      return <DynamicTextInputList key={field.name} field={field} />;
    case 'textInput':
      return <WorkflowStepTextInputField key={field.name} field={field} />;
    case 'selectInput':
      return <WorkflowStepSelectInput key={field.name} field={field} />;
    case 'numberInput':
      return <WorkflowStepNumberInput key={field.name} field={field} />;
    case 's3FileSelectionInput':
      return <S3FileSelector key={field.name} field={field} />;
    case 'dynamicFormInputs':
      return (
        <DynamicFormInputsList
          key={field.name}
          name={field.name}
          field={field.fields}
          arrName={field.arrName}
          addButton={field.addButton}
        />
      );
    default:
      return null;
  }
};
