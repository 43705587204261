import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PromptsGalleryDrawer from './PromptsGalleryDrawer';
import { observer } from 'mobx-react';
import { useStore } from '../../hooks/useStore';
import { INITIAL_PROMPT } from '../../models/Prompt';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onPromptSelect: (promptContent: string) => void;
}

const ChooseFromPromptsGalleryModal = ({ isOpen, onClose, onPromptSelect }: Props) => {
    const { promptsStore: { currentPrompt } } = useStore();

    React.useEffect(() => {
        if (currentPrompt.content !== INITIAL_PROMPT.content) {
            onPromptSelect(currentPrompt.content);
        }
    }, [currentPrompt]);

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Choose from Prompts Gallery
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <PromptsGalleryDrawer />
            </DialogContent>
        </Dialog>
    );
};

export default observer(ChooseFromPromptsGalleryModal);