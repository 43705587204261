import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

import { useStore } from "../../../hooks/useStore";
import FilePreview from "../../../pages/ConversationPage/components/Files/FilePreview";
import FullHeightScreenWrapper from "../../../components/FullHeightComponentWrapper/FullHeightScreenWrapper";
import styled from "@emotion/styled";
import { COLOR_BORDER_PRIMARY, COLOR_WHITE } from "../../../constants/colors";
import { Box } from "@material-ui/core";
import { SIZES_SMALL } from "../../../constants/sizes";

const RORLetterResultsPage = () => {
  const {
    workFlowStore: { selectedWorkflowRun: { result, variables: { caseNumber, policyNumber, complaintFile, policyFormFiles} } },
    fileStore: { getFileById },
    conversationStore: { toggleShowFilePreview }
  } = useStore();

  const [previewFile, setPreviewFile] = useState<any>();

  useEffect(() => {
    const fetchFile = async () => {
      const file = await getFileById(result.generated_ror_file);

      setPreviewFile(file);
    };

    if (result.generated_ror_file) {
      fetchFile();
    }

    toggleShowFilePreview();

    return () => {
        toggleShowFilePreview();
    };
  }, [result.generated_ror_file]);

  const getFileNames = (files: any[]) => {
    return files.map((file: any) => {
      return file.name.split('/').pop();
    }).join(', ');
  }

  return (
    <PageWrapper>
    <VariablesContainer>
        <VariableItem>
          <Label>Case Number:</Label>
          <Value>{caseNumber}</Value>
        </VariableItem>
        <VariableItem>
          <Label>Policy Number:</Label>
          <Value>{policyNumber}</Value>
        </VariableItem>
        <VariableItem>
          <Label>Complaint File:</Label>
          <Value>{getFileNames(complaintFile)}</Value>
        </VariableItem>
        <VariableItem>
          <Label>Policy Form Files:</Label>
          <Value>{getFileNames(policyFormFiles)}</Value>
        </VariableItem>
    </VariablesContainer>
    <PreviewFileWrapper>
      {!!previewFile && <FilePreview file={previewFile} isGridViewIconDisabled={true} redactedViewDisabled={true}/>}
    </PreviewFileWrapper>
    </PageWrapper>
  );
};

export default observer(RORLetterResultsPage);

const PageWrapper = styled(Box)<{ height?: string }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  background: ${COLOR_WHITE};
  overflow-x: hidden;
`;

const PreviewFileWrapper = styled(Box)<{ height?: string }>`
  display: flex;
  width: 100%;
  overflow: hidden;

  & > div {
    overflow: hidden;
    height: unset;
  }
`;

const VariablesContainer = styled.div`
  padding: 16px;
  max-width: 30%;
`;

const VariableItem = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Label = styled.span`
  font-weight: 600;
  margin-right: 8px;
  min-width: 120px;
`;

const Value = styled.span`
  word-break: break-all;
`;
