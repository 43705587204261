import AttachFileIcon from '@mui/icons-material/AttachFile';
import Box from '@mui/material/Box';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { PickerCallback } from 'react-google-drive-picker/dist/typeDefs';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';

import { COLOR_DIVIDER, COLOR_PRIMARY, COLOR_WHITE, GRAY_COLORS } from '../../constants/colors';
import { Paths } from '../../constants/routes';

import { useStore } from '../../hooks/useStore';
import { Conversation, DEFAULT_SETTINGS } from '../../models/Conversation';
import BlackTooltip from '../../tooltips/BlackTooltip';
import { getDocumentsKey, uploadDocumentSecurely } from '../../utils/documentsS3';
import { uploadDriveFile } from '../../utils/uploadFile';
import GoogleDrivePicker from '../googleDrive/GoogleDrivePicker';
import RedactDataModal from '../modal/RedactDataModal';
import { IconButton } from '@mui/material';
import { OrganizationModel } from '../../models/OrganizationModel';
import { getFileExtensionFromName } from '../../utils/getFileExtensionFromName';
import { convertDocxFileToPdf } from '../../utils/convertDocxToPdf';
import { LoadingSpinner } from '../spinner/LoadingSpinner';
import { checkIsConversationNeeded } from '../../utils/checkIsConversationNeeded';

const AMAZON_Q_MAX_FILE_SIZE = 10 * 1024 * 1024;

const MyInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

interface UploadDocumentProps {
  disabled?: boolean;
  disabledMessage?: string;
  assignFilesToConversation: (
    files: {
      id: string;
      name: string;
    }[],
    conversationToAssignFilesTo?: Conversation
  ) => Promise<Conversation>;
  options: OrganizationModel[];
  onUploadComplete?: () => void;
}

const UploadDocument = ({
  disabled,
  disabledMessage,
  assignFilesToConversation,
  options,
  onUploadComplete,
}: UploadDocumentProps) => {
  const {
    userStore: { userData },
    appState: { s3DocumentsApi, fileApi, googleDriveApi },
    conversationStore: {
      setIsUploadInProgress,
      setUploadProgress,
      setUploadingConversationId,
      setCancelUploadToken,
      cancelFileUpload,
      setUploadingFileId,
      conversationSettingsState,
    },
    fileStore: { createFile, convertToDocx },
    localizationStore: { i18next: i18n },
    WorkSpaceStore: { workSpaceList },
  } = useStore();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  // setting default file model
  const shouldSkipRedaction = process.env.REACT_APP_SKIP_REDACTION === 'true';
  const inputRef = useRef<HTMLInputElement>(null);
  const [isAskForRedactionModalOpen, setIsAskForRedactionModalOpen] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState<File[]>();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const isAmazonQModelSelected = conversationSettingsState?.model.includes('amazonQ');

  useEffect(() => {
    if (workSpaceList) {
      let result = workSpaceList.find(option => option.workflowKey === 'redactFilesForChat');
      let isEnabled = result ? result.isEnabled : false;
      setShowModal(isEnabled);
    }
  }, [workSpaceList]);

  const onUploadGoogleDriveDocuments = async (googleDriveObject?: { data: PickerCallback; token: string }) => {
    if (googleDriveObject?.data?.docs?.length) {
      const filesToUpload: {
        id: string;
        name: string;
      }[] = [];
      const fileKeys: string[] = [];

      await Promise.all(
        googleDriveObject?.data?.docs.map(async file => {
          const fileToUpload = {
            type: 'application/pdf',
            name: `${file.name}.pdf`,
            lastModified: file.lastEditedUtc,
            size: file.sizeBytes,
            webkitRelativePath: '',
            lastModifiedDate: file.lastEditedUtc,
          };

          if (fileToUpload) {
            const fileKey = getDocumentsKey(userData.id, fileToUpload.name);
            const createdFileEntry = await createFile({ key: fileKey, name: fileToUpload.name });

            setUploadingFileId(createdFileEntry.id);

            filesToUpload.push({ id: createdFileEntry.id, name: createdFileEntry.name });
            fileKeys.push(fileKey);
          }
        })
      );

      const conversation = await assignFilesToConversation(filesToUpload);

      setIsUploadInProgress(true);
      setUploadingConversationId(conversation.id);
      setUploadProgress(0);

      navigate(`${Paths.CHAT}/${conversation?.id}`, { state: { dontRefresh: true } });

      const objToUpload = {
        documentIds: googleDriveObject?.data?.docs?.map(item => ({
          id: item.id,
        })),
        accessToken: googleDriveObject.token,
        fileKeys: fileKeys,
      };

      await uploadDriveFile({
        googleDriveObject: objToUpload,
        conversationId: conversation?.id,
        createdFiles: filesToUpload.map(item => item.id),
        googleDriveApi,
        fileApi,
        setIsUploadInProgress,
        cancelFileUpload,
        addToast,
        i18n,
      });
    }
  };

  const onUploadDocument = async (e?: React.ChangeEvent<HTMLInputElement>) => {
    const files = e?.target?.files;

    if (!files) {
      return;
    }

    let filesToUpload = Array.from(files);

    if (isAmazonQModelSelected) {
      filesToUpload = Array.from(files).filter(file => {
        if (file.size > AMAZON_Q_MAX_FILE_SIZE) {
          addToast(`${file.name} exceeds the 10MB file size limit.`, { appearance: 'error' });
          return false;
        }
        return true;
      });

      if (filesToUpload.length === 0) {
        return;
      }
    }

    setFilesToUpload(filesToUpload);

    if (shouldSkipRedaction) {
      await handleUploadFiles(filesToUpload, true);
    } else {
      setIsAskForRedactionModalOpen(true);
    }
  };

  const handleUploadFiles = async (filesToUpload: File[] | undefined, skipRedaction: boolean) => {
    if (!filesToUpload) {
      return;
    }

    const createdFilesEntries = [];
    let index = 0;
    let conversationToAssignFilesTo: Conversation | undefined;

    try {
      setLoading(true);
      for await (let fileToUpload of filesToUpload) {
        index++;

        const extension = getFileExtensionFromName(fileToUpload.name);

        if (checkIsConversationNeeded(extension)) {
          fileToUpload = await convertDocxFileToPdf(fileToUpload, convertToDocx);
        }
        const fileKey = getDocumentsKey(userData.id, fileToUpload.name);
        const createdFileEntry = await createFile({ key: fileKey, name: fileToUpload.name, skipRedaction });
        createdFilesEntries.push(createdFileEntry);

        const conversation = await assignFilesToConversation(
          [{ id: createdFileEntry.id, name: createdFileEntry.name }],
          conversationToAssignFilesTo && conversationToAssignFilesTo
        );

        conversationToAssignFilesTo = conversation;

        setIsUploadInProgress(true);
        setUploadingConversationId(conversation.id);
        setUploadingFileId(createdFileEntry.id);
        setUploadProgress(0);

        if (conversation.id) {
          navigate(`${Paths.CHAT}/${conversation.id}`, { state: { dontRefresh: true } });
        }

        if (process.env.REACT_APP_USE_DOCUMENT_ENCRYPTION === 'true') {
          const status = await s3DocumentsApi.uploadDocuments(fileToUpload, createdFileEntry.id, {
            setProgress: setUploadProgress,
            setCancelToken: setCancelUploadToken,
          });

          if (status === 201) {
            setIsUploadInProgress(false);
            fileApi.startProcessing(conversation.id, createdFileEntry.id);
          }
        }

        const preSignedPutUrl = await s3DocumentsApi.generateDocumentsWriteUrl(fileKey, fileToUpload.type);

        const status = await uploadDocumentSecurely(preSignedPutUrl, fileToUpload, {
          setProgress: setUploadProgress,
          setCancelToken: setCancelUploadToken,
        });

        if (status === 200) {
          setIsUploadInProgress(false);
          if (conversation.id) {
            fileApi.startProcessing(conversation.id, createdFileEntry.id);
          }
        }
      }

      setFilesToUpload([]);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Upload canceled');
        await cancelFileUpload();
      } else {
        console.error('Error during upload:', error);
      }
    } finally {
      handleUploadComplete(); // calling this to know that upload is completed
      setLoading(false);
    }
  };

  const handleUploadComplete = () => {
    if (onUploadComplete) {
      onUploadComplete();
    }
  };

  const uploadDocument = (
    <Box sx={{ display: 'flex', gap: '8px' }}>
      {false && (
        <GoogleDrivePicker
          callback={onUploadGoogleDriveDocuments}
          disabled={disabled}
          googleDriveApi={googleDriveApi}
        />
      )}

      <IconButton
        component={'label'}
        htmlFor={'upload-document'}
        disabled={disabled}
        onClick={() => {
          if (conversationSettingsState.model.includes('file') || conversationSettingsState.model.includes('agent')) {
            return;
          }
        }}
      >
        <AttachFileIcon sx={{ width: '20px', height: '20px', fill: GRAY_COLORS.GRAY_6 }} />
      </IconButton>
      {!disabled && (
        <MyInput
          multiple
          type="file"
          ref={inputRef}
          id={'upload-document'}
          name={'upload-document'}
          onChange={onUploadDocument}
          onClick={(event: any) => {
            event.target.value = null;
          }}
          accept={
            'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .md, .epub, ' +
            '.csv, application/JSON, text/plain, application/vnd.ms-excel, image/png, ' +
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }
        />
      )}
      <RedactDataModal
        isOpen={isAskForRedactionModalOpen}
        onClose={() => setIsAskForRedactionModalOpen(false)}
        filesToUpload={filesToUpload}
        handleUploadFiles={handleUploadFiles}
      />
    </Box>
  );

  if (loading) {
    return (
      <Box sx={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <LoadingSpinner />
      </Box>
    );
  }
  return disabled && disabledMessage ? (
    <BlackTooltip title={disabledMessage} placement={'top'} arrow>
      {uploadDocument}
    </BlackTooltip>
  ) : (
    uploadDocument
  );
};

export default UploadDocument;
