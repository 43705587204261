export enum Paths {
  MEMBER_SIGN_UP = '/sign-up-member',
  ADMIN_SIGN_UP = '/sign-up-admin',
  LOGIN = '/login',
  INIT = '/',
  CHAT = '/chat',
  CONVERSATION = '/dashboard/conversation',
  PROMPTS_GALLERY = '/prompts-gallery',
  HISTORY = '/history',
  KNOWLEDGE_BASE = '/knowledge-base',
  WORKFLOWS = '/workflows',
  PROFILE = '/profile',
  ADMIN_PORTAL = '/admin-portal',
  ADMIN_PORTAL_USERS = '/admin-portal/users',
  SEARCH_BY_IMAGE = '/search-by-image',
  AGENT_LIST = '/agent',
  ADD_AGENT = '/add-agent',
  HELP_CENTER = '/help-center',
  FORTRESS_RESULT = '/fortress-result',
  ADCB_RESULT = '/adcb-result',
  TEXT_EDITOR_DEMO = '/text-editor-demo',
}

export type BreadcrumbsRoute = {
  path: string;
  redirect?: string;
  name: string;
};

export const BreadcrumbsRoutes: BreadcrumbsRoute[] = [
  { path: '/items', name: 'items' },
  { path: '/items/:itemId/details', name: 'ItemDetails' },
];
